import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialPaginationModel } from "../../constants/variables";
import { useAppSelector } from "../hooks";

const roleSlice = createSlice({
  name: "role",
  initialState: initialPaginationModel,
  reducers: {
    setRolePaginationModel: (state, action: PayloadAction<PaginationModel>) => {
      state.page = action.payload.page;
      state.pageSize = action.payload.pageSize;
    },
  },
});

export const { setRolePaginationModel } = roleSlice.actions;

export default roleSlice.reducer;

export const useRolePaginationModel = () =>
  useAppSelector((state) => state.role);
