import { useEffect, useRef, useState } from "react";
import ServerGrid, { ServerGridProps } from "./common/ServerGrid";
import { FilterOperator } from "../constants/enums";
import { useFormik } from "formik";
import { createFeatureValidationSchema } from "../util/validateSchema";

import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  Drawer,
  IconButton,
  Snackbar,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";

import {
  GridActionsCellItem,
  GridRowParams,
  GridRowSelectionModel,
} from "@mui/x-data-grid";

import { Edit } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { getApiErrorMessage } from "../util/getApiErrorMessage";
import { getFeatureColumns } from "../util/gridColumns/featuresColumns";
import {
  useCreateProductFeatureMutation,
  useDeleteProductFeatureMutation,
  useGetProductFeaturesQuery,
  useUpdateProductFeatureMutation,
} from "../app/services/product";
import {
  setFeaturePaginationModel,
  useFeaturePaginationModel,
} from "../app/features/featureSlice";
import { useAppDispatch } from "../app/hooks";

const ProductFeature: React.FC<ProductFormProps> = ({ selectedProductId }) => {
  const dispatch = useAppDispatch();
  const featureColumns = prepareColumns();
  const [rows, setRows] = useState<Feature[]>([]);
  const [filterValue, setFilterValue] = useState("");
  const [filterOperator, setFilterOperator] = useState(FilterOperator.Equals);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [rowCountState, setRowCountState] = useState(0);
  const [openCreateFeatureDialog, setOpenCreateFeaturetDialog] =
    useState(false);
  const [openUpdateFeatureDialog, setOpenUpdateFeatureDialog] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);
  const paginationModel = useFeaturePaginationModel();
  const setPaginationModel = (paginationModel: PaginationModel) =>
    dispatch(setFeaturePaginationModel(paginationModel));
  const featureNameRef = useRef<HTMLInputElement>(null);

  const [selectedRow, setSelectedRow] = useState<ProductFeatureObj>({
    id: "",
    productFeatureName: "",
    description: "",
    createdDate: "",
  });

  /*
   * Formik
   * */
  const createFeatureFormik = useFormik({
    initialValues: {
      //productId: "",
      productFeatureName: "",
      description: "",
    },

    validationSchema: createFeatureValidationSchema,

    onSubmit: async (values, { resetForm }) => {
      if (values.productFeatureName.trim().length === 0) {
        setSnackbarMessage("Feature Name Required");
        setIsSnackbarOpen(true);
        return;
      }

      if (values.description.trim().length === 0) {
        setSnackbarMessage("Description Required");
        setIsSnackbarOpen(true);
        return;
      }

      try {
        // Update feature
        if (openUpdateFeatureDialog) {
          const requestModal: UpdateProductFeature = {
            productFeatureName: values.productFeatureName.trim(),
            description: values.description.trim(),
          };

          const request: UpdateProductFeatureReq = {
            productFeatureId: selectedRow.id,
            productFeature: requestModal,
          };

          let payload = await updateProductFeature(request);
          setSnackbarMessage(
            getApiErrorMessage(payload, "Feature updated successfully")
          );
        } else {
          // Create feature
          const requestModal: CreateProductFeature = {
            productFeatures: [
              {
                productFeatureName: values.productFeatureName.trim(),
                description: values.description.trim(),
              },
            ],
          };

          const request: CreateProductFeatureReq = {
            productId: selectedProductId,
            body: requestModal,
          };

          let payload = await createProductFeature(request);
          setSnackbarMessage(
            getApiErrorMessage(payload, "Feature created successfully")
          );
        }

        resetForm();
        setIsSnackbarOpen(true);
        refetchFeatures();
      } catch (error) {
        setSnackbarMessage(getApiErrorMessage(error, "Something went wrong."));
        setIsSnackbarOpen(true);
      }
    },
  });

  /*
   * API calls
   * */
  const [
    createProductFeature,
    {
      error: createFeatureError,
      isError: createFeatureIsError,
      isLoading: createFeatureIsLoading,
      isSuccess: createFeatureIsSuccess,
    },
  ] = useCreateProductFeatureMutation();

  const {
    data: features = [],
    isFetching: featuresIsFetching,
    isSuccess: featuresIsSuccess,
    isError: featuresIsError,
    error: featuresError,
    refetch: refetchFeatures,
  } = useGetProductFeaturesQuery(
    {
      productId: selectedProductId,
      page: paginationModel.page + 1,
      pageSize: paginationModel.pageSize,
    },
    { skip: !selectedProductId }
  );

  const [
    updateProductFeature,
    {
      error: updateFeatureError,
      isError: updateFeatureIsError,
      isLoading: updateFeatureIsLoading,
      isSuccess: updateFeatureIsSuccess,
    },
  ] = useUpdateProductFeatureMutation();

  const [
    deleteProductFeature,
    {
      error: deleteFeatureError,
      isError: deleteFeatureIsError,
      isLoading: deleteFeatureIsLoading,
      isSuccess: deleteFeatureIsSuccess,
    },
  ] = useDeleteProductFeatureMutation();

  /*
   * Effects
   * */
  useEffect(() => {
    if (features?.data) {
      const modifiedData = features.data.map((item: any) => {
        const formattedDate = new Date(item.createdDate).toLocaleString();
        return {
          ...item,
          createdDate: formattedDate,
        };
      });
      setRowCountState(features?.rowCount || 0);
      setRows(modifiedData);
    }
  }, [featuresIsSuccess, features]);

  useEffect(() => {
    handleCreateFeatureDialogClose();
  }, [createFeatureIsSuccess]);

  useEffect(() => {
    (updateFeatureIsError || updateFeatureIsSuccess) &&
      handleUpdateFeatureDialogClose();
  }, [updateFeatureIsError, updateFeatureIsSuccess]);

  useEffect(() => {
    if (openCreateFeatureDialog || openUpdateFeatureDialog) {
      featureNameRef.current?.focus();
    }
  }, [openCreateFeatureDialog, openUpdateFeatureDialog]);

  /*
   * Handlers
   * */

  function prepareColumns() {
    const setActions = (params: GridRowParams<any>) => {
      return [
        <GridActionsCellItem
          icon={<Edit />}
          label="Edit"
          onClick={() => {
            setSelectedRow(params.row);
            createFeatureFormik.setValues({
              ...createFeatureFormik.values,
              //productId: selectedProduct,
              productFeatureName: params.row.productFeatureName,
              description: params.row.description,
            });
            handleUpdateFeatureDialogOpen();
          }}
        />,
      ];
    };

    return getFeatureColumns(setActions);
  }

  const handleSnackbarClose = () => setIsSnackbarOpen(false);

  const handleCreateFeatureDialogOpen = () => setOpenCreateFeaturetDialog(true);

  const handleCreateFeatureDialogClose = () => {
    setOpenCreateFeaturetDialog(false);
    createFeatureFormik.resetForm();
  };

  const handleUpdateFeatureDialogOpen = () => setOpenUpdateFeatureDialog(true);

  const handleUpdateFeatureDialogClose = () =>
    setOpenUpdateFeatureDialog(false);

  const handleClickDelete = async () => {
    const request: DeleteFeatureReq = {
      productId: selectedProductId,
      productFeatureId: [],
    };
    request.productFeatureId.push(selectedRow.id);
    var payload = await deleteProductFeature(request);

    let message = "";
    if ((payload as any)?.error?.data?.infoMessage) {
      message = (payload as any)?.error?.data?.infoMessage;
    } else message = "Feature deleted successfully";

    setSnackbarMessage(message);
    setIsSnackbarOpen(true);

    refetchFeatures();
    setConfirmationDialogOpen(false);
  };

  const getPageNumbers = (totalPages: number, currentPage: number) => {
    const delta = 2;
    const left = currentPage - delta;
    const right = currentPage + delta + 1;
    const range: number[] = [];
    const pages: number[][] = [];

    for (let i = 1; i <= totalPages; i++)
      if (i === 1 || i === totalPages || (i >= left && i < right))
        range.push(i);

    range.forEach((page, index) => {
      if (index === 0 || page - range[index - 1] !== 1) pages.push([]);
      pages[pages.length - 1].push(page);
    });

    return pages;
  };

  const totalPages = Math.ceil(
    (features?.rowCount ?? rowCountState) / paginationModel.pageSize
  );

  const pageNumbers = getPageNumbers(totalPages, paginationModel.page + 1);

  const serverGridProps: ServerGridProps = {
    rows,
    columns: featureColumns,
    paginationModel,
    rowCount: features?.rowCount ?? rowCountState ?? 0,
    setPaginationModel,
    rowSelectionModel,
    setRowSelectionModel,
    isLoading: featuresIsFetching,
    keepNonExistentRowsSelected: true,
    pageNumbers,
    totalPages,
    filterOperator,
    setFilterOperator,
    filterValue,
    setFilterValue,
    hiddenColumns: ["id"],
    usageType: "Feature",
  };

  return (
    <Box
      sx={{
        marginLeft: { xs: 2, sm: 4, md: 10, lg: 20 },
        marginRight: { xs: 2, sm: 4, md: 10, lg: 20 },
        marginTop: 2,
      }}
    >
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          severity={
            createFeatureIsSuccess ||
            updateFeatureIsSuccess ||
            deleteFeatureIsSuccess
              ? "success"
              : "error"
          }
          onClose={handleSnackbarClose}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Box>
        <Box display={"flex"} justifyContent={"flex-end"}>
          <Tooltip title="Add New Feature">
            <Button
              sx={{
                paddingRight: "15px",
                color: "white",
                margin: "10px",
                backgroundColor: "primary.main",
                "&:hover": {
                  backgroundColor: "primary.dark",
                },
              }}
              onClick={handleCreateFeatureDialogOpen}
            >
              <AddIcon />
              <Typography variant="button">Feature</Typography>
            </Button>
          </Tooltip>
        </Box>

        <ServerGrid {...serverGridProps} />
      </Box>

      {/*---------- Create/Update Feature ----------*/}
      <Drawer
        anchor="right"
        id="create-feature-drawer"
        open={openCreateFeatureDialog || openUpdateFeatureDialog}
        onClose={() => {
          handleCreateFeatureDialogClose();
          handleUpdateFeatureDialogClose();
        }}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Box style={{ width: "500px" }}>
          <Toolbar variant="dense">
            <Box flexGrow={1}>
              <Typography variant="h5">
                {openUpdateFeatureDialog ? "Update Feature" : "Add New Feature"}
              </Typography>
            </Box>
            <IconButton
              onClick={() => {
                handleCreateFeatureDialogClose();
                handleUpdateFeatureDialogClose();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <Container>
            <Box
              component="form"
              autoComplete="off"
              py={2}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <TextField
                name="productFeatureName"
                label="Feature Name"
                autoFocus
                fullWidth
                required
                size="small"
                margin="dense"
                value={createFeatureFormik.values.productFeatureName}
                error={Boolean(createFeatureFormik.errors.productFeatureName)}
                helperText={createFeatureFormik.errors.productFeatureName}
                onChange={createFeatureFormik.handleChange}
              />

              <TextField
                name="description"
                label="Description"
                fullWidth
                multiline
                rows={4}
                required
                margin="dense"
                value={createFeatureFormik.values.description}
                error={Boolean(createFeatureFormik.errors.description)}
                helperText={createFeatureFormik.errors.description}
                onChange={createFeatureFormik.handleChange}
              />
            </Box>
            <Box display={"flex"} justifyContent={"flex-end"}>
              <Button
                variant="contained"
                size="small"
                onClick={() => createFeatureFormik.handleSubmit()}
                disabled={createFeatureIsLoading || updateFeatureIsLoading}
              >
                Save
                {(createFeatureIsLoading || updateFeatureIsLoading) && (
                  <CircularProgress
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: -12,
                      marginLeft: -12,
                      color: "secondary",
                    }}
                    size={24}
                  />
                )}
              </Button>
            </Box>
          </Container>
        </Box>
      </Drawer>

      {/*---------- Confirm Dialogs ----------*/}
      <Dialog
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
      >
        <Box>
          <DialogTitle>
            Are you sure you want to delete this feature?
          </DialogTitle>
          <DialogActions>
            {deleteFeatureIsLoading ? (
              <Button
                variant="outlined"
                onClick={handleClickDelete}
                disabled={deleteFeatureIsLoading}
              >
                Yes
                {deleteFeatureIsLoading && (
                  <CircularProgress
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: -12,
                      marginLeft: -12,
                      color: "secondary",
                    }}
                    size={24}
                  />
                )}
              </Button>
            ) : (
              <Button variant="outlined" onClick={handleClickDelete}>
                Yes
              </Button>
            )}

            <Button
              variant="outlined"
              onClick={() => setConfirmationDialogOpen(false)}
            >
              No
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
};

export default ProductFeature;
