export function UpdateAddressCallback(payload) {
  try {
    window.chrome.webview.postMessage({ EVENT_TYPE: payload });
  } catch (error) {
    console.error("Error in UpdateAddressCallback:", error);
  }
}

export function SaveAvaTaxSettingsCallback(payload) {
  window.chrome.webview.postMessage({ EVENT_TYPE: payload });
}
