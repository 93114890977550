import { Breadcrumbs, Link, Stack, Typography } from "@mui/material";

function BreadcrumbsControl(props: any) {
    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" href="/dashboard/app">
            Dashboard
        </Link>,
        <Typography key="3" color="text.primary">
            {props.page}
        </Typography>,
    ];
    return (
        <Stack spacing={2} sx={{ margin: "10px" }}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
                {breadcrumbs}
            </Breadcrumbs>
        </Stack>
    );
}

export default BreadcrumbsControl;
