import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useAppSelector } from "../hooks";

const product: Product = {
id: "",
productName: "",
description: "",
productType: "Web"
}
const manageProductSlice = createSlice({
  name: "manageProduct",
  initialState: product,
  reducers: {
    setCurrentProductModel: (
      state,
      action: PayloadAction<Product>
    ) => {
      state.id = action.payload.id;
      state.productName = action.payload.productName;
      state.description = action.payload.description;
      state.productType = action.payload.productType;
    },
  },
});

export const { setCurrentProductModel } = manageProductSlice.actions;

export default manageProductSlice.reducer;

export const useCurrentProductModel = () =>
  useAppSelector((state) => state.manageProduct);
