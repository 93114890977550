import { idmsApi } from "./api";
import { API_DEALER_ENDPOINT } from "../../constants/endpoints";
import { convertObjectToQueryParams } from "../../util/convertObjectToQueryParams";

const dealerApi = idmsApi.injectEndpoints({
  endpoints: (builder) => ({
    getDealers: builder.query<any, GetDealership>({
      query: (getDealerships) =>
        `${API_DEALER_ENDPOINT}?${convertObjectToQueryParams(
            getDealerships
        )}`,
    }),

    getDmsList: builder.query<any, void>({
        query: () => API_DEALER_ENDPOINT + "/Dms",
    }),

    assignProduct: builder.mutation<any, DealerAssignProduct>({
        query: (request) => ({
          url: API_DEALER_ENDPOINT + "/AssignProduct",
          method: "POST",
          body: request,
        }),
    }),
    unAssignProduct: builder.mutation<any, DealerUnAssignProduct>({
      query: (request) => ({
        url: API_DEALER_ENDPOINT + "/UnAssignProduct",
        method: "POST",
        body: request,
      }),
  }),
    getDealersByProduct: builder.query<any, string>({
      query: (productId) => `${API_DEALER_ENDPOINT}/${productId}`,
    }),
  }),
});

export const {
  useGetDealersQuery,
  useGetDmsListQuery,
  useAssignProductMutation,
  useUnAssignProductMutation,
  useGetDealersByProductQuery
} = dealerApi;
