import { FC, useEffect, useState } from "react";
import ServerGrid, { ServerGridProps } from "./common/ServerGrid";
import { FilterOperator } from "../constants/enums";
import { useFormik } from "formik";
import { createFeatureValidationSchema } from "../util/validateSchema";

import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  Snackbar,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import {
  GridActionsCellItem,
  GridRowParams,
  GridRowSelectionModel,
} from "@mui/x-data-grid";

import { Edit } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { getApiErrorMessage } from "../util/getApiErrorMessage";
import { getFeatureColumns } from "../util/gridColumns/featuresColumns";
import {
  useCreateProductFeatureMutation,
  useDeleteProductFeatureMutation,
  useGetProductFeaturesQuery,
  useGetProductsQuery,
  useUpdateProductFeatureMutation,
} from "../app/services/product";
import { useProductPaginationModel } from "../app/features/productSlice";
import {
  setFeaturePaginationModel,
  useFeaturePaginationModel,
} from "../app/features/featureSlice";
import { useAppDispatch } from "../app/hooks";

const Features: FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const featureColumns = prepareColumns();
  const [rows, setRows] = useState<Feature[]>([]);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [filterOperator, setFilterOperator] = useState(FilterOperator.Equals);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [rowCountState, setRowCountState] = useState(0);
  const [openCreateFeatureDialog, setOpenCreateFeaturetDialog] =
    useState(false);
  const [openUpdateFeatureDialog, setOpenUpdateFeatureDialog] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);
  const productPaginationModel = useProductPaginationModel();
  const paginationModel = useFeaturePaginationModel();
  const setPaginationModel = (paginationModel: PaginationModel) =>
    dispatch(setFeaturePaginationModel(paginationModel));

  const [selectedRow, setSelectedRow] = useState<ProductFeatureObj>({
    id: "",
    productFeatureName: "",
    description: "",
    createdDate: "",
  });

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  /*
   * Formik
   * */

  const createFeatureFormik = useFormik({
    initialValues: {
      productId: "",
      productFeatureName: "",
      description: "",
    },

    validationSchema: createFeatureValidationSchema,

    onSubmit: async (values, { resetForm }) => {
      // Update feature
      if (openUpdateFeatureDialog) {
        const requestModal: UpdateProductFeature = {
          productFeatureName: values.productFeatureName,
          description: values.description,
        };

        const request: UpdateProductFeatureReq = {
          productFeatureId: selectedRow.id,
          productFeature: requestModal,
        };

        let payload = await updateProductFeature(request);

        setSnackbarMessage(
          getApiErrorMessage(payload, "Feature updated successfully")
        );
        setIsSnackbarOpen(true);
      } else {
        // Create feature
        const requestModal: CreateProductFeature = {
          productFeatures: [
            {
              productFeatureName: values.productFeatureName,
              description: values.description,
            },
          ],
        };

        const request: CreateProductFeatureReq = {
          productId: values.productId,
          body: requestModal,
        };

        var payload = await createProductFeature(request);

        setSnackbarMessage(
          getApiErrorMessage(payload, "Feature created successfully")
        );
        setIsSnackbarOpen(true);
      }

      resetForm();
      refetchFeatures();
    },
  });

  /*
   * API calls
   * */

  const [
    createProductFeature,
    {
      error: createFeatureError,
      isError: createFeatureIsError,
      isLoading: createFeatureIsLoading,
      isSuccess: createFeatureIsSuccess,
    },
  ] = useCreateProductFeatureMutation();

  const {
    data: products,
    isError: productsIsError,
    error: productsError,
    isLoading: productsIsLoading,
  } = useGetProductsQuery({
    pageSize: productPaginationModel.pageSize,
    page: productPaginationModel.page + 1,
  });

  const {
    data: features = [],
    isFetching: featuresIsFetching,
    isSuccess: featuresIsSuccess,
    isError: featuresIsError,
    error: featuresError,
    refetch: refetchFeatures,
  } = useGetProductFeaturesQuery({
    productId: selectedProduct,
    page: paginationModel.page + 1,
    pageSize: paginationModel.pageSize,
  });

  const [
    updateProductFeature,
    {
      error: updateFeatureError,
      isError: updateFeatureIsError,
      isLoading: updateFeatureIsLoading,
      isSuccess: updateFeatureIsSuccess,
    },
  ] = useUpdateProductFeatureMutation();

  const [
    deleteProductFeature,
    {
      error: deleteFeatureError,
      isError: deleteFeatureIsError,
      isLoading: deleteFeatureIsLoading,
      isSuccess: deleteFeatureIsSuccess,
    },
  ] = useDeleteProductFeatureMutation();

  /*
   * Effects
   * */

  useEffect(() => {
    if (features?.data) {
      const modifiedData = features.data.map((item: any) => {
        const formattedDate = new Date(item.createdDate).toLocaleString();
        return {
          ...item,
          createdDate: formattedDate,
        };
      });
      setRowCountState(features?.rowCount || 0);
      setRows(modifiedData);
    }
  }, [featuresIsSuccess, features]);

  // Fetch product features when the selected product changes
  useEffect(() => {
    if (selectedProduct) {
      refetchFeatures();
    }
  }, [selectedProduct, refetchFeatures]);

  // Select the first product when products are fetched
  useEffect(() => {
    if (products && products.data.length > 0) {
      setSelectedProduct(products.data[0].id);
    }
  }, [products]);

  useEffect(() => {
    (createFeatureIsError || createFeatureIsSuccess) &&
      handleCreateFeatureDialogClose();
  }, [createFeatureIsError, createFeatureIsSuccess]);

  useEffect(() => {
    (updateFeatureIsError || updateFeatureIsSuccess) &&
      handleUpdateFeatureDialogClose();
  }, [updateFeatureIsError, updateFeatureIsSuccess]);

  // useEffect(() => {
  //   if (featuresIsError) {
  //     setSnackbarMessage(
  //       getApiErrorMessage({
  //         error: {
  //           ...featuresError,
  //         },
  //       })
  //     );
  //     setIsSnackbarOpen(true);
  //   }
  // }, [featuresIsError]);

  /*
   * Handlers
   * */

  function prepareColumns() {
    const setActions = (params: GridRowParams<any>) => {
      return [
        <GridActionsCellItem
          icon={<Edit />}
          label="Edit"
          onClick={() => {
            setSelectedRow(params.row);
            createFeatureFormik.setValues({
              ...createFeatureFormik.values,
              productId: selectedProduct,
              productFeatureName: params.row.productFeatureName,
              description: params.row.description,
            });
            handleUpdateFeatureDialogOpen();
          }}
        />,

        <GridActionsCellItem
          icon={<DeleteForeverIcon />}
          label="Delete"
          onClick={() => {
            setSelectedRow(params.row);
            setConfirmationDialogOpen(true);
          }}
        />,
      ];
    };

    return getFeatureColumns(setActions);
  }

  const handleSnackbarClose = () => setIsSnackbarOpen(false);

  const handleCreateFeatureDialogOpen = () => setOpenCreateFeaturetDialog(true);

  const handleCreateFeatureDialogClose = () => {
    setOpenCreateFeaturetDialog(false);
    createFeatureFormik.resetForm();
  };

  const handleUpdateFeatureDialogOpen = () => setOpenUpdateFeatureDialog(true);

  const handleUpdateFeatureDialogClose = () =>
    setOpenUpdateFeatureDialog(false);

  const handleClickDelete = async () => {
    const request: DeleteFeatureReq = {
      productId: selectedProduct,
      productFeatureId: [],
    };
    request.productFeatureId.push(selectedRow.id);

    var payload = await deleteProductFeature(request);

    let message = "";
    if ((payload as any)?.error?.data?.infoMessage) {
      message = (payload as any)?.error?.data?.infoMessage;
    } else message = "Feature deleted successfully";

    setSnackbarMessage(message);
    setIsSnackbarOpen(true);

    refetchFeatures();
    setConfirmationDialogOpen(false);
  };

  const getPageNumbers = (totalPages: number, currentPage: number) => {
    const delta = 2;
    const left = currentPage - delta;
    const right = currentPage + delta + 1;
    const range: number[] = [];
    const pages: number[][] = [];

    for (let i = 1; i <= totalPages; i++)
      if (i === 1 || i === totalPages || (i >= left && i < right))
        range.push(i);

    range.forEach((page, index) => {
      if (index === 0 || page - range[index - 1] !== 1) pages.push([]);
      pages[pages.length - 1].push(page);
    });

    return pages;
  };

  const totalPages = Math.ceil(
    (features?.rowCount ?? rowCountState) / paginationModel.pageSize
  );

  const pageNumbers = getPageNumbers(totalPages, paginationModel.page + 1);

  const serverGridProps: ServerGridProps = {
    rows,
    columns: featureColumns,
    paginationModel,
    rowCount: features?.rowCount ?? rowCountState ?? 0,
    setPaginationModel,
    rowSelectionModel,
    setRowSelectionModel,
    isLoading: featuresIsFetching,
    keepNonExistentRowsSelected: true,
    pageNumbers,
    totalPages,
    filterOperator,
    setFilterOperator,
    filterValue,
    setFilterValue,
    hiddenColumns: ["id"],
  };

  return (
    <Box id="base-padding">
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          severity={
            createFeatureIsSuccess ||
            updateFeatureIsSuccess ||
            deleteFeatureIsSuccess
              ? "success"
              : "error"
          }
          onClose={handleSnackbarClose}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Box mx={3}>
        {/* <Typography variant="h5" gutterBottom>
          Product Features
        </Typography> */}

        <Box display={"flex"} my={2}>
          <IconButton
            aria-label="add a features"
            sx={{
              padding: "21px",
              mr: 3,
              color: "white",
              backgroundColor: "primary.main",
              "&:hover": {
                backgroundColor: "primary.dark",
              },
            }}
            onClick={handleCreateFeatureDialogOpen}
          >
            <AddIcon />
          </IconButton>

          <TextField
            name="productId"
            select
            label="Select Product"
            fullWidth
            required
            margin="dense"
            value={selectedProduct}
            onChange={(e) => setSelectedProduct(e.target.value)}
            sx={{
              backgroundColor: "white",
              borderRadius: 7,
              "& .MuiInputBase-root": { borderRadius: 7 },
            }}
          >
            {productsIsLoading && <MenuItem value={0}>Loading...</MenuItem>}
            {productsIsError && (
              <MenuItem value={0}>
                Error loading products. Please refresh the page.
              </MenuItem>
            )}
            {(products?.data as Product[])?.map((product, index) => (
              <MenuItem key={index} value={product.id}>
                {product.productName}
              </MenuItem>
            ))}
          </TextField>
        </Box>

        <ServerGrid {...serverGridProps} />
      </Box>

      {/*---------- Create/Update Feature Dialogs ----------*/}

      <Dialog
        id="create-feature-dialog"
        open={openCreateFeatureDialog || openUpdateFeatureDialog}
        fullWidth
        fullScreen={isMobile}
        onKeyUp={(e) => {
          if (e?.key === "Enter") createFeatureFormik.handleSubmit();
        }}
        onClose={handleCreateFeatureDialogClose}
      >
        <DialogTitle>
          {openUpdateFeatureDialog ? "Update Feature" : "Add New Feature"}

          <IconButton
            aria-label="close"
            onClick={() => {
              handleCreateFeatureDialogClose();
              handleUpdateFeatureDialogClose();
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            title="Close"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <Divider />

        <DialogContent>
          <Box
            component="form"
            autoComplete="off"
            py={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              "& .MuiInputBase-root, .MuiButton-root": {
                boxShadow: "0px 0px 10px 0px rgba(0.5,0,0,0.1)",
                borderRadius: 7,
              },
            }}
          >
            <TextField
              sx={{ mb: 2 }}
              name="productId"
              select
              label="Select Product"
              fullWidth
              required
              margin="dense"
              disabled={openUpdateFeatureDialog}
              value={createFeatureFormik.values.productId}
              error={Boolean(createFeatureFormik.errors.productId)}
              helperText={createFeatureFormik.errors.productId}
              onChange={createFeatureFormik.handleChange}
            >
              {productsIsLoading && <MenuItem value={0}>Loading...</MenuItem>}
              {productsIsError && (
                <MenuItem value={0}>
                  Error loading products. Please refresh the page.
                </MenuItem>
              )}
              {(products?.data as Product[])?.map((product, index) => (
                <MenuItem key={index} value={product.id}>
                  {product.productName}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              name="productFeatureName"
              label="Feature Name"
              fullWidth
              autoFocus
              required
              margin="dense"
              value={createFeatureFormik.values.productFeatureName}
              error={Boolean(createFeatureFormik.errors.productFeatureName)}
              helperText={createFeatureFormik.errors.productFeatureName}
              onChange={createFeatureFormik.handleChange}
            />

            <TextField
              name="description"
              label="Description"
              fullWidth
              autoFocus
              required
              margin="dense"
              value={createFeatureFormik.values.description}
              error={Boolean(createFeatureFormik.errors.description)}
              helperText={createFeatureFormik.errors.description}
              onChange={createFeatureFormik.handleChange}
            />
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            onClick={() => createFeatureFormik.handleSubmit()}
            disabled={createFeatureIsLoading || updateFeatureIsLoading}
            sx={{
              margin: "-10px 18px 0 0",
            }}
          >
            Save
            {(createFeatureIsLoading || updateFeatureIsLoading) && (
              <CircularProgress
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: -12,
                  marginLeft: -12,
                  color: "secondary",
                }}
                size={24}
              />
            )}
          </Button>
        </DialogActions>
      </Dialog>

      {/*---------- Confirm Dialogs ----------*/}

      <Dialog
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
      >
        <Box>
          <DialogTitle>
            Are you sure you want to delete this feature?
          </DialogTitle>
          <DialogActions>
            {deleteFeatureIsLoading ? (
              <Button
                variant="outlined"
                onClick={handleClickDelete}
                disabled={deleteFeatureIsLoading}
              >
                Yes
                {deleteFeatureIsLoading && (
                  <CircularProgress
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: -12,
                      marginLeft: -12,
                      color: "secondary",
                    }}
                    size={24}
                  />
                )}
              </Button>
            ) : (
              <Button variant="outlined" onClick={handleClickDelete}>
                Yes
              </Button>
            )}

            <Button
              variant="outlined"
              onClick={() => setConfirmationDialogOpen(false)}
            >
              No
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
};

export default Features;
