import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Checkbox,
  Button,
  Box,
  TextField,
  Typography,
  CircularProgress,
  Snackbar,
  Alert,
  TablePagination,
  InputAdornment,
  IconButton,
  MenuItem,
  DialogContent,
  Dialog,
  DialogTitle,
  DialogActions,
  RadioGroup,
  FormControlLabel,
  Radio,
  Autocomplete,
} from "@mui/material";
import { useGetRolesQuery } from "../app/services/roles";
import { useGetProductUsersQuery } from "../app/services/userProduct";
import {
  useAssignUserRoleMutation,
  useDeleteUserRoleMutation,
} from "../app/services/userRole";
import { getApiErrorMessage } from "../util/getApiErrorMessage";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { useGetDealersByProductQuery } from "../app/services/dealer";
import DeleteIcon from "@mui/icons-material/Delete";
import { StyledTableHead } from "../constants/variables";

const AssignRole: React.FC<ProductFormProps> = ({ selectedProductId }) => {
  const [selectedRole, setSelectedRole] = useState("");
  const [currentPage, setCurrentPage] = useState<number>(0);
  const initialUserIds: string[] = [];
  const [selectedUsers, setSelectedUsers] = useState<CreateUserRole>({
    roleId: selectedRole,
    userId: initialUserIds,
  });
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [totalCount, setTotalCount] = useState<number>(1);
  const [pageSize, setRowsPerPage] = React.useState(25);
  const [searchText, setSearchText] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedDealer, setSelectedDealer] = useState<any>();
  const [selectedDmsDealerId, setSelectedDmsDealerId] = useState<any>("");
  const [unassignDialogOpen, setUnAssignnDialogOpen] = useState(false);
  const [unassignedUserRole, setUnAssignedUserRole] = useState<any>();
  const [unassignedUser, setUnassignedUser] = useState("");
  const [filterCase, setFilterCase] = useState<string>("All");

  /*
   * API Calls
   * */
  const { data: roles, isFetching: rolesFetching } = useGetRolesQuery({
    productId: selectedProductId,
    dmsDealerId: selectedDmsDealerId,
    page: 1,
    pageSize,
  });

  const {
    data: productUsers,
    isFetching: productUsersIsFetching,
    refetch: refetchUsers,
  } = useGetProductUsersQuery(
    {
      productId: selectedProductId,
      page: currentPage + 1,
      pageSize,
      dealerId: selectedDealer,
      filterType: filterCase,
      searchText,
    },
    {
      refetchOnMountOrArgChange: true, // Refetch data every time the component mounts or the arguments change
      refetchOnReconnect: true, // Refetch data when the network reconnects
      skip: false, // Ensure the query runs without skipping
    }
  );
  const [
    doAssignUserRole,
    {
      isLoading: assignUserRoleIsLoading,
      isError: assignUserRoleIsError,
      error: assignUserRoleError,
      isSuccess: assignUserRoleIsSuccess,
    },
  ] = useAssignUserRoleMutation();

  const [
    deleteUserRole,
    {
      isLoading: deleteUserRoleIsLoading,
      isError: deleteUserRoleIsError,
      isSuccess: deleteUserRoleIsSuccess,
    },
  ] = useDeleteUserRoleMutation();

  const {
    data: productDealers = [],
    isFetching: productDealersIsFetching,
    isSuccess: productDealersIsSuccess,
    isError: productDealersIsError,
    error: productDealersError,
    refetch: refetchProductDealers,
  } = useGetDealersByProductQuery(selectedProductId);

  /*
   * Effects
   * */
  useEffect(() => {
    if (productUsers && productUsers.rowCount) {
      setTotalCount(productUsers.rowCount);
    }
  }, [productUsers, pageSize]);

  /*
   * Functions
   * */
  const handleSave = async () => {
    // Open the dialog when saving
    setIsDialogOpen(true);
  };
  const handleRoleSelectChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newRoleId = event.target.value;
    setSelectedRole(newRoleId);
    setSelectedUsers((prevState) => ({ ...prevState, roleId: newRoleId }));
  };

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    setCurrentPage(page);
    refetchUsers();
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
    refetchUsers();
  };
  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    Id: string
  ) => {
    const isChecked = event.target.checked;
    setSelectedUsers((prevState) => {
      const newUserIds = isChecked
        ? [...prevState.userId, Id]
        : prevState.userId.filter((id) => id !== Id);
      return { ...prevState, userId: newUserIds };
    });
  };
  const handleSnackbarClose = () => setIsSnackbarOpen(false);
  const handleSearch = (text: string) => {
    setSearchText(text);
    refetchUsers();
  };

  function handleCloseDialog() {
    setIsDialogOpen(false);
  }

  async function handleAssignRole() {
    try {
      if (selectedRole === "") {
        setSnackbarMessage("Please select a role");
        setIsSnackbarOpen(true);
        return;
      }

      if (selectedUsers.userId.length === 0) {
        setSnackbarMessage("Please select a user");
        setIsSnackbarOpen(true);
        return;
      }

      const payload = await doAssignUserRole(selectedUsers).unwrap();
      const roleName = roles?.data.find(
        (role: { id: any }) => role.id === selectedRole
      )?.name;
      setSnackbarMessage(
        getApiErrorMessage(
          payload,
          `"${roleName}" Role assigned to the selected user(s) successfully`
        )
      );
      setSelectedUsers({ roleId: "", userId: [] });
      refetchUsers();
      setSelectedRole("");
      setIsDialogOpen(false);
      setIsSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage(getApiErrorMessage(error, "Failed to assign Role"));
      setIsSnackbarOpen(true);
    }
  }
  async function handleDeleteUserRole() {
    try {
      if (unassignedUserRole) {
        const deleteUserRoleRequest: DeleteUserRole = {
          roleId: [unassignedUserRole.roleId],
          userId: unassignedUserRole.userId,
        };
        const payload = await deleteUserRole(deleteUserRoleRequest).unwrap();
        setSnackbarMessage(
          getApiErrorMessage(payload, "Role Unassigned Successfully")
        );
        setUnAssignnDialogOpen(false);
        setIsSnackbarOpen(true);
        refetchUsers();
      }
    } catch (error) {
      setSnackbarMessage(getApiErrorMessage(error, "Failed to Unaasign Role"));
      setIsSnackbarOpen(true);
      console.error("Failed to delete user role:", error);
    }
  }
  return (
    <Box
      sx={{
        marginLeft: { xs: 2, sm: 4, md: 10, lg: 20 },
        marginRight: { xs: 2, sm: 4, md: 10, lg: 20 },
        marginTop: 2,
      }}
    >
      {snackbarMessage !== "" && (
        <Snackbar
          open={isSnackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            severity={
              assignUserRoleIsSuccess || deleteUserRoleIsSuccess
                ? "success"
                : "error"
            }
            onClose={handleSnackbarClose}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      )}
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-between",
          alignItems: "center",
          gap: 2,
          mt: 2,
        }}
      >
        <Autocomplete
          value={
            productDealers && productDealers.length > 0
              ? productDealers?.find(
                  (dealer: { id: any }) => dealer.id === selectedDealer
                ) || null
              : null
          }
          onChange={(event, newValue) => {
            setCurrentPage(0);
            const newSelectedDealer = newValue?.id || "";
            const newSelectedDmsDealerId = newValue?.dmsDealerId || "";
            setSelectedDealer(newSelectedDealer);
            setSelectedDmsDealerId(newSelectedDmsDealerId);
            setSelectedUsers({ roleId: "", userId: [] });
          }}
          options={Array.isArray(productDealers) ? productDealers : []}
          noOptionsText="No Dealer Available"
          disabled={!productDealers || productDealers.length === 0}
          getOptionLabel={(option) =>
            option.companyName?.trim()
              ? `${option.companyName} (${option.dmsDealerId})`
              : option.name?.trim()
              ? `${option.name} (${option.dmsDealerId})`
              : `(${option.dmsDealerId})`
          }
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Dealer"
              fullWidth
              size="small"
              required
              margin="dense"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {productDealersIsFetching && (
                      <CircularProgress color="inherit" size={20} />
                    )}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              sx={{
                backgroundColor: "background.paper",
                boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.2)",
                "&:hover": {
                  backgroundColor: "background.default",
                },
                "& .Mui-focused": {
                  borderColor: "primary.main",
                  boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.3)",
                },
              }}
            />
          )}
          sx={{
            flex: { xs: "1 1 auto", sm: "0 0 30%" },
          }}
          disableClearable
        />
        <TextField
          id="search"
          placeholder="Search by Username, Email"
          autoComplete="true"
          fullWidth
          size="small"
          margin="dense"
          value={searchText}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault(); // Prevent default behavior
            }
          }}
          onChange={(e) => {
            setCurrentPage(0);
            setSearchText(e.target.value);
          }}
          sx={{
            flexBasis: { xs: "100%", lg: "50%" },
            backgroundColor: "background.paper",
            borderRadius: 1,
            boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.2)",
            "&:hover": {
              backgroundColor: "background.default",
            },
            "& .Mui-focused": {
              borderColor: "primary.main",
              boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.3)",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: searchText && (
              <InputAdornment position="end">
                <IconButton
                  aria-label="clear search"
                  onClick={() => {
                    setSearchText("");
                    handleSearch("");
                  }}
                  edge="end"
                  title="Clear search"
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <RadioGroup
          row
          aria-label="user-case"
          name="user-case"
          value={filterCase}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setSelectedUsers({ roleId: "", userId: [] });
            setCurrentPage(0);
            setFilterCase((event.target as HTMLInputElement).value);
          }}
          sx={{
            flex: { xs: "1 1 auto", sm: "0 0 auto" },
            justifyContent: { xs: "center", sm: "flex-start" },
            mt: { xs: 2, sm: 0 },
          }}
        >
          <FormControlLabel value="All" control={<Radio />} label="All" />
          <FormControlLabel
            value="Assigned"
            control={<Radio />}
            label="Assigned"
          />
          <FormControlLabel
            value="Unassigned"
            control={<Radio />}
            label="Unassigned"
          />
        </RadioGroup>
      </Box>
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: "530px",
          display: "flex",
          flexDirection: "column",
          mt: 2,
        }}
      >
        {productUsersIsFetching ? (
          <Box display="flex" justifyContent="center" alignItems="center" p={3}>
            <CircularProgress />
          </Box>
        ) : selectedDealer && productUsers && productUsers.data.length > 0 ? (
          <>
            <Box sx={{ flex: "1 1 auto", overflowY: "auto" }}>
              <Table size="small" aria-label="users table" stickyHeader>
                <StyledTableHead>
                  <TableRow>
                    <TableCell padding="checkbox"></TableCell>
                    <TableCell>Username</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>Dms Dealer ID</TableCell>
                    <TableCell>Company Name</TableCell>
                    <TableCell>Role</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </StyledTableHead>
                <TableBody>
                  {productUsers &&
                    productUsers.data.map((user: any) => (
                      <TableRow
                        sx={{
                          "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" },
                          "&:last-child td, &:last-child th": { border: 0 },
                          ".MuiTableCell-root": {
                            padding: "6px 16px",
                            fontSize: "0.875rem",
                          },
                        }}
                      >
                        {(() => {
                          return (
                            <>
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={
                                    selectedUsers.userId.includes(
                                      user?.userId
                                    ) || false
                                  }
                                  onChange={(event) =>
                                    handleCheckboxChange(event, user?.userId)
                                  }
                                  disabled={user?.roleStatus === "Assigned"}
                                />
                              </TableCell>
                              <TableCell>{user?.userName}</TableCell>
                              <TableCell>{user?.email}</TableCell>
                              <TableCell>{user?.phoneNumber}</TableCell>
                              <TableCell>{user?.dmsDealerId}</TableCell>
                              <TableCell>{user.companyName?.trim() ? user?.companyName : user?.dealerName}</TableCell>
                              <TableCell>{user?.roleName}</TableCell>
                              <TableCell>
                                <Button
                                  size="small"
                                  variant="outlined"
                                  sx={{
                                    color: "#007bff",
                                    borderColor: "#007bff",
                                    textTransform: "none",
                                    "&:hover": {
                                      borderColor: "#0056b3",
                                      backgroundColor: "rgba(0, 123, 255, 0.1)",
                                    },
                                  }}
                                  disabled={user?.roleStatus === "Unassigned"}
                                  onClick={() => {
                                    setUnassignedUser(user?.userName);
                                    setUnAssignedUserRole(user);
                                    setUnAssignnDialogOpen(true);
                                  }}
                                  startIcon={<DeleteIcon />}
                                >
                                  Unassign
                                </Button>
                              </TableCell>
                            </>
                          );
                        })()}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
            <Box sx={{ flexShrink: 0 }}>
              <TablePagination
                component="div"
                count={totalCount}
                page={currentPage}
                onPageChange={handlePageChange}
                rowsPerPage={pageSize}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[25, 50, 75, 100]}
              />
            </Box>
          </>
        ) : selectedDealer && productUsers.data.length === 0 ? (
          <Box display="flex" justifyContent="center" alignItems="center" p={3}>
            <Typography>No users found for this Dealer.</Typography>
          </Box>
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center" p={3}>
            <Typography>No users available. Please select a Dealer.</Typography>
          </Box>
        )}
      </TableContainer>
      {productUsers ? (
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <Button
            variant="contained"
            onClick={handleSave}
            size="small"
            disabled={!selectedDealer || productUsers.data.length === 0}
          >
            Select Role
          </Button>
        </Box>
      ) : null}

      {/* Dialog for Role Selection */}
      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Select Role</DialogTitle>
        <DialogContent>
          <TextField
            aria-label="Select Role"
            name="roleId"
            select
            label="Select Role"
            required
            size="small"
            margin="dense"
            fullWidth
            value={selectedRole}
            onChange={handleRoleSelectChange}
            sx={{
              backgroundColor: "background.paper",
              boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.2)",
              "&:hover": {
                backgroundColor: "background.default",
              },
              "& .Mui-focused": {
                borderColor: "primary.main",
                boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.3)",
              },
              alignSelf: "flex-start",
              minWidth: 400,
              width: 400 + selectedRole.length * 2,
            }}
          >
            {roles?.data && roles.data.length > 0 ? (
              (roles?.data as Role[])?.map((role, index) => (
                <MenuItem key={index} value={role.id}>
                  {`${role.name}`}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="" disabled>
                No Role Available
              </MenuItem>
            )}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button
            disabled={assignUserRoleIsLoading}
            onClick={handleAssignRole}
            color="primary"
          >
            Assign Role
            {assignUserRoleIsLoading && (
              <CircularProgress
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: -12,
                  marginLeft: -12,
                  color: "secondary",
                }}
                size={24}
              />
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={unassignDialogOpen}
        onClose={() => {
          setUnAssignedUserRole(undefined);
          setUnAssignnDialogOpen(false);
          setUnassignedUser("");
        }}
      >
        <Box>
          <DialogTitle>
            "{unassignedUser}" will be removed from the "
            {unassignedUserRole?.roleName}" role. Are you sure you want to
            continue?
          </DialogTitle>

          <DialogActions>
            <Button variant="outlined" onClick={handleDeleteUserRole}>
              Yes
              {deleteUserRoleIsLoading && (
                <CircularProgress
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: -12,
                    marginLeft: -12,
                    color: "secondary",
                  }}
                  size={24}
                />
              )}
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                setUnAssignnDialogOpen(false);
                setUnAssignedUserRole(undefined);
                setUnassignedUser("");
              }}
            >
              No
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
};

export default AssignRole;
