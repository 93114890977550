export function getApiErrorMessage(payload: any, successMessage?: string) {
  console.log(payload);
  let message = "";
  if ((payload as any)?.error?.data) {
    var errors =
      (payload as any)?.error?.data?.Errors ||
      (payload as any)?.error?.data?.errors;
    for (var key in errors)
      if (errors?.hasOwnProperty(key)) message += errors[key] + "\n";
  } else message = successMessage || message;
  return message;
}
