import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useAppSelector } from "../hooks";

interface ISalesTaxEnginesTypeInitialState {
  dealerId: number | undefined;
}

const SalesTaxEnginesTypeInitialState: ISalesTaxEnginesTypeInitialState = {
  dealerId: undefined,
};

const salesTaxSlice = createSlice({
  name: "salesTax",
  initialState: SalesTaxEnginesTypeInitialState,
  reducers: {
    setDealerId: (state, action: PayloadAction<number>) => {
      state.dealerId = action.payload;
    },
  },
});

export const { setDealerId } = salesTaxSlice.actions;

export default salesTaxSlice.reducer;

export const useGetDealerId = () =>
  useAppSelector((state) => state.salesTax.dealerId);
