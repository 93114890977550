import {
  Alert,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  Typography,
} from "@mui/material";
import { GridRowParams, GridActionsCellItem } from "@mui/x-data-grid";
import { FC, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useGetProductsQuery } from "../app/services/product";
import {
  useAssignUserProductMutation,
  useGetUserProductsQuery,
  useUnassignUserProductMutation,
} from "../app/services/userProduct";
import { getApiErrorMessage } from "../util/getApiErrorMessage";
import CancelIcon from "@mui/icons-material/Cancel";
import "./common/Common.css";
import ServerGrid, { ServerGridProps } from "./common/ServerGrid";
import { getProductColumns } from "../util/gridColumns/productColumns";
import { useProductPaginationModel } from "../app/features/productSlice";

const UserProducts: FC = () => {
  const { userId } = useParams();
  const { userName, dmsDealerId } = useLocation().state as any;
  const userProductColumns = prepareColumns();
  const [rows, setRows] = useState<CreateProduct[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const productPaginationModel = useProductPaginationModel();
  const [selectedUserProduct, setSelectedUserProduct] =
    useState<CreateUserProduct>({
      userId: userId !== undefined ? userId : "",
      productIds: [],
    });

  /*
   * API calls
   * */

  const {
    data: userProducts,
    isFetching: userProductsIsFetching,
    isLoading: userProductsIsLoading,
    error: userProductsError,
    isError: userProductsIsError,
    isSuccess: userProductsIsSuccess,
  } = useGetUserProductsQuery(userId !== undefined ? userId : "");

  const {
    data: products,
    error: productsError,
    isError: productsIsError,
    isLoading: productsIsLoading,
  } = useGetProductsQuery({
    pageSize: productPaginationModel.pageSize,
    page: productPaginationModel.page + 1,
  });

  const [
    doAssignUserProduct,
    {
      isLoading: assignUserProductIsLoading,
      error: assignUserProductError,
      isError: assignUserProductIsError,
      isSuccess: assignUserProductIsSuccess,
    },
  ] = useAssignUserProductMutation();

  const [
    doUnassignUserProduct,
    {
      isLoading: unassignUserProductIsLoading,
      error: unassignUserProductError,
      isError: unassignUserProductIsError,
      isSuccess: unassignUserProductIsSuccess,
    },
  ] = useUnassignUserProductMutation();

  /*
   * Effects
   * */

  useEffect(() => {
    if (userProductsIsSuccess) setRows(userProducts);
  }, [userProducts, userProductsIsSuccess]);

  useEffect(() => {
    if (userProductsIsError) {
      setSnackbarMessage(
        getApiErrorMessage({
          error: {
            ...userProductsError,
          },
        })
      );
      setIsSnackbarOpen(true);
    }
  }, [userProductsIsError]);

  useEffect(() => {
    if (productsIsError) {
      setSnackbarMessage(
        getApiErrorMessage({
          error: {
            ...productsError,
          },
        })
      );
      setIsSnackbarOpen(true);
    }
  }, [productsIsError]);

  useEffect(() => {
    if (userProductsIsError) {
      setSnackbarMessage(
        getApiErrorMessage({
          error: {
            ...userProductsError,
          },
        })
      );
      setIsSnackbarOpen(true);
    }
  }, [userProductsIsError]);

  /*
   * Handlers
   * */

  function prepareColumns() {
    const setActions = (params: GridRowParams<any>) => {
      return [
        <GridActionsCellItem
          icon={<CancelIcon />}
          label="Unassign Product"
          title="Unassign Product"
          onClick={() => handleUnassignUserProductDialogOpen(params.row.id)}
          disabled={assignUserProductIsLoading || unassignUserProductIsLoading}
        />,
      ];
    };

    return getProductColumns(setActions);
  }

  const handleSnackbarClose = () => {
    setIsSnackbarOpen(false);
    setSnackbarMessage("");
  };

  const handleAssignProducts = () => {
    if (selectedProducts.length > 0)
      doAssignUserProduct({
        userId: userId !== undefined ? userId : "",
        productIds: selectedProducts,
      }).then(() => {
        setSelectedProducts([]);
        setIsSelectOpen(false);
        setIsSnackbarOpen(true);
        setSnackbarMessage("Assigned Product successfully");
      });
  };

  const handleConfirm = async () => {
    setConfirmationDialogOpen(false);
    selectedUserProduct.productIds.length > 0 &&
      (await doUnassignUserProduct(selectedUserProduct));
    setIsSnackbarOpen(true);
    setSnackbarMessage("Unassigned Product successfully");
  };

  const handleUnassignUserProductDialogOpen = (productId: string) => {
    setSelectedUserProduct((prev) => ({
      ...prev,
      productIds: [productId],
    }));
    setConfirmationDialogOpen(true);
  };

  const serverGridProps: ServerGridProps = {
    rows,
    columns: userProductColumns,
    rowCount: userProducts?.length,
    isLoading: userProductsIsFetching || userProductsIsLoading,
    keepNonExistentRowsSelected: true,
    hiddenColumns: ["id"],
  };

  return (
    <Box id="base-padding">
      {snackbarMessage !== "" && (
        <Snackbar
          open={isSnackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert
            severity={
              assignUserProductIsSuccess || unassignUserProductIsSuccess
                ? "success"
                : "error"
            }
            onClose={handleSnackbarClose}
          >
            {snackbarMessage ||
              JSON.stringify(
                assignUserProductIsError
                  ? assignUserProductError
                  : unassignUserProductError,
                null,
                2
              ) ||
              "Error performing operation"}
          </Alert>
        </Snackbar>
      )}

      <Box mx={3}>
        <Box display={"flex"}>
          <Typography variant="h5" gutterBottom>
            Product(s) of
          </Typography>
          <Box width={8} />
          <Typography variant="h5" gutterBottom color={"#3f51b5"}>
            {userName}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            "& .MuiInputBase-root": {
              boxShadow: "0px 0px 10px 0px rgba(0.5,0,0,0.1)",
              borderRadius: 7,
              bgcolor: "white",
            },
          }}
        >
          <FormControl fullWidth margin="normal">
            <InputLabel id="productLabel">Assign Product</InputLabel>
            <Select
              id="productIds"
              labelId="productLabel"
              label="Assign Product"
              fullWidth
              multiple
              open={isSelectOpen}
              onOpen={() => setIsSelectOpen(true)}
              onClose={() => setIsSelectOpen(false)}
              value={selectedProducts}
              onChange={(e) => setSelectedProducts(e.target.value as string[])}
              disabled={
                assignUserProductIsLoading || unassignUserProductIsLoading
              }
              input={<OutlinedInput label="Assign Product" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                  {selected &&
                    selected.length > 0 &&
                    (products?.data as Product[])
                      ?.filter((product) => selected.includes(product.id))
                      ?.map((product, index) => (
                        <Chip
                          key={index}
                          label={product.productName}
                          sx={{ m: 0.5 }}
                        />
                      ))}
                </Box>
              )}
            >
              {productsIsLoading && <MenuItem>Loading...</MenuItem>}
              {productsIsError && (
                <MenuItem>
                  Error loading products. Please refresh the page.
                </MenuItem>
              )}
              {(products?.data as Product[])?.map((product, index) => (
                <MenuItem
                  key={index}
                  value={product.id}
                  disabled={
                    userProducts?.some(
                      (userProduct) => userProduct.id === product.id
                    ) || false
                  }
                >
                  {product.productName}
                </MenuItem>
              ))}
              <Divider />
              <Button
                variant="contained"
                color="primary"
                onClick={handleAssignProducts}
                disabled={
                  selectedProducts.length === 0 || assignUserProductIsLoading
                }
                sx={{ ml: 2, my: 1 }}
              >
                Apply
                {assignUserProductIsLoading && (
                  <CircularProgress
                    sx={{
                      position: "absolute",
                      color: "secondary",
                    }}
                    size={24}
                  />
                )}
              </Button>
            </Select>
          </FormControl>

          {(assignUserProductIsLoading || unassignUserProductIsLoading) && (
            <LinearProgress />
          )}
        </Box>

        <ServerGrid {...serverGridProps} />
      </Box>

      <Dialog
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
      >
        <Box>
          <DialogTitle>
            Are you sure you want to unassign this product?
          </DialogTitle>
          <DialogActions>
            <Button variant="outlined" onClick={handleConfirm}>
              Yes
            </Button>
            <Button
              variant="outlined"
              onClick={() => setConfirmationDialogOpen(false)}
            >
              No
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
};

export default UserProducts;
