import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Snackbar,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { createProductValidationSchema } from "../util/validateSchema";
import { getApiErrorMessage } from "../util/getApiErrorMessage";
import {
  useCreateProductMutation,
  useGetProductsQuery,
  useUpdateProductMutation,
} from "../app/services/product";
import { useProductPaginationModel } from "../app/features/productSlice";
import {
  setCurrentProductModel,
  useCurrentProductModel,
} from "../app/features/manageProductSlice";
import { useAppDispatch } from "../app/hooks";

interface NewProductProps {
  selectedProductId: string;
  onProductCreate: () => void;
}

const ProductForm: React.FC<NewProductProps> = ({
  selectedProductId,
  onProductCreate,
}) => {
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const productPaginationModel = useProductPaginationModel();
  const currentProduct = useCurrentProductModel();

  const dispatch = useAppDispatch();

  const createProductFormik = useFormik({
    initialValues: {
      productName: "",
      productType: "Web",
      description: "",
    } as CreateProduct,

    validationSchema: createProductValidationSchema,

    onSubmit: async (values) => {
      //Product Name Validation
      values.productName = values.productName.trim();
      if (selectedProductId) {
        // Update product
        const payload: any = await updateProduct({
          productId: selectedProductId,
          product: values,
        });
        dispatch(
          setCurrentProductModel({
            id: payload.data?.id,
            productName: payload.data?.productName,
            description: payload.data?.description,
            productType: payload.data?.productType,
          })
        );
        setSnackbarMessage(
          getApiErrorMessage(payload, `Product Updated Successfully`)
        );
      } else {
        // Create product
        const payload: any = await createProduct(values);

        dispatch(
          setCurrentProductModel({
            id: payload.data?.id,
            productName: payload.data?.productName,
            description: payload.data?.description,
            productType: payload.data?.productType,
          })
        );
        selectedProductId = payload.data?.id;
        localStorage.setItem("newlyCreatedProduct", selectedProductId);
        setSnackbarMessage(
          getApiErrorMessage(payload, `Product Created Successfully`)
        );

        if (payload.data?.id) {
          setTimeout(() => {
            onProductCreate(); // Switch to ProductFeature tab
          }, 1000); // Delay of 1 second
        }
      }
      setIsSnackbarOpen(true);
      refetchProducts();
    },
  });

  /*
   * API calls
   * */
  const [
    createProduct,
    {
      error: createProductError,
      isError: createProductIsError,
      isLoading: createProductIsLoading,
      isSuccess: createProductIsSuccess,
    },
  ] = useCreateProductMutation();

  const [
    updateProduct,
    {
      error: updateProductError,
      isError: updateProductIsError,
      isLoading: updateProductIsLoading,
      isSuccess: updateProductIsSuccess,
    },
  ] = useUpdateProductMutation();

  const {
    data: products,
    isError: productsIsError,
    error: productsError,
    isLoading: productsIsLoading,
    refetch: refetchProducts,
  } = useGetProductsQuery({
    pageSize: productPaginationModel.pageSize,
    page: productPaginationModel.page + 1,
  });

  const isLoading = createProductIsLoading || updateProductIsLoading;

  /*
   * Effects
   * */

  useEffect(() => {
    const newProductId = localStorage.getItem("newlyCreatedProduct");

    const idToSelect = selectedProductId || (newProductId ? newProductId : "");

    if (idToSelect && products && products.data) {
      const selectedProduct = products.data.find(
        (product: { id: any }) => product.id === idToSelect
      );
      if (selectedProduct) {
        createProductFormik.setValues({
          productName: selectedProduct.productName,
          productType: selectedProduct.productType,
          description: selectedProduct.description,
        });
      } else if (currentProduct) {
        createProductFormik.setValues({
          productName: currentProduct.productName,
          productType: currentProduct.productType,
          description: currentProduct.description,
        });
      }
    }
  }, [selectedProductId, products]);
  const handleSnackbarClose = () => setIsSnackbarOpen(false);

  return (
    <Box
      sx={{
        marginLeft: { xs: 2, sm: 4, md: 10, lg: 20 },
        marginRight: { xs: 2, sm: 4, md: 10, lg: 20 },
      }}
    >
      {" "}
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          severity={
            createProductIsSuccess || updateProductIsSuccess
              ? "success"
              : "error"
          }
          onClose={handleSnackbarClose}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            autoFocus={selectedProductId == null ? true : false}
            label="Product Name"
            name="productName"
            size="small"
            variant="outlined"
            margin="normal"
            value={createProductFormik.values.productName}
            error={Boolean(createProductFormik.errors.productName)}
            helperText={createProductFormik.errors.productName}
            onChange={createProductFormik.handleChange}
          />
        </Grid>
        <Grid item xs={4} md={2} sm={4}>
          <TextField
            select
            size="small"
            fullWidth
            label="Product Type"
            name="productType"
            variant="outlined"
            margin="normal"
            value={createProductFormik.values.productType}
            error={Boolean(createProductFormik.errors.productType)}
            helperText={createProductFormik.errors.productType}
            onChange={createProductFormik.handleChange}
          >
            <MenuItem value="Web">Web</MenuItem>
            <MenuItem value="Mobile">Mobile</MenuItem>
            <MenuItem value="Desktop">Desktop</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={8}>
          <TextField
            fullWidth
            label="Description"
            name="description"
            variant="outlined"
            margin="normal"
            multiline
            rows={4}
            value={createProductFormik.values.description}
            error={Boolean(createProductFormik.errors.description)}
            helperText={createProductFormik.errors.description}
            onChange={createProductFormik.handleChange}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}
        >
          <Button
            variant="contained"
            color="primary"
            disabled={isLoading}
            onClick={(event) => {
              event.preventDefault();
              createProductFormik.handleSubmit();
            }}
          >
            {selectedProductId ? "Update" : "Save"}
            {isLoading && (
              <CircularProgress
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: -12,
                  marginLeft: -12,
                  color: "secondary",
                }}
                size={24}
              />
            )}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProductForm;
