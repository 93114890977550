import {
  AppBar,
  Avatar,
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import Link from "@mui/material/Link/Link";
import "../../components/common/Common.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Logout } from "@mui/icons-material";
import { useState } from "react";
import {
  logout,
  useCurrentAuthenticatedUser,
} from "../../app/features/authSlice";
import { useAppDispatch } from "../../app/hooks";
import { resetProductState } from "../../app/features/productSlice";

const Header = () => {
  const { pathname } = useLocation();
  const isUserLoggedIn = !(pathname === "/" || pathname === "/login" || pathname === "/landing");
  const userEmail = localStorage.getItem("idms_email") || "";
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isAccountMenuOpen = Boolean(anchorEl);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const authState = useCurrentAuthenticatedUser();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem("idms_email");
    dispatch(logout());
    dispatch(resetProductState());
    navigate("/login", { replace: true });
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <AppBar
          position="fixed"
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Link href="/" underline="none">
              <Typography variant="h5" component="div" sx={{ color: "white" }}>
                Target User Management Portal
              </Typography>
            </Link>

            {isUserLoggedIn && (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h6" sx={{ p: 2, color: "#ffffff" }}>
                  Hello, {userEmail}
                </Typography>
                <Tooltip title="Settings">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    aria-controls={
                      isAccountMenuOpen ? "account-menu" : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={isAccountMenuOpen ? "true" : undefined}
                  >
                    <Avatar>
                      {authState?.username?.charAt(0)?.toUpperCase() || ""}
                    </Avatar>
                  </IconButton>
                </Tooltip>

                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={isAccountMenuOpen}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  {/* <MenuItem onClick={handleClose}>
                    <Avatar /> Profile
                  </MenuItem> */}
                  {/* <Divider /> */}
                  <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                      <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Menu>
              </Box>
            )}
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
};

export default Header;
