export const getPageNumbers = (totalPages: number, currentPage: number) => {
  const delta = 2;
  const left = currentPage - delta;
  const right = currentPage + delta + 1;
  const range: number[] = [];
  const pages: number[][] = [];

  for (let i = 1; i <= totalPages; i++)
    if (i === 1 || i === totalPages || (i >= left && i < right)) range.push(i);

  range.forEach((page, index) => {
    if (index === 0 || page - range[index - 1] !== 1) pages.push([]);
    pages[pages.length - 1].push(page);
  });

  return pages;
};
