import { idmsApi } from "./api";
import { API_PRODUCT_ENDPOINT } from "../../constants/endpoints";
import { convertObjectToQueryParams } from "../../util/convertObjectToQueryParams";

const productApi = idmsApi.injectEndpoints({
  endpoints: (builder) => ({
    createProduct: builder.mutation<any, CreateProduct>({
      query: (request) => ({
        url: API_PRODUCT_ENDPOINT,
        method: "POST",
        body: request,
      }),
    }),

    getProducts: builder.query<any, GetProductsSearchCriteria>({
      query: (getProductsSearchCriteria) =>
        `${API_PRODUCT_ENDPOINT}?${convertObjectToQueryParams(
          getProductsSearchCriteria
        )}`,
    }),

    updateProduct: builder.mutation<any, UpdateProduct>({
      query: ({ productId, product }) => ({
        url: `${API_PRODUCT_ENDPOINT}/updateProduct/${productId}`,
        method: "PUT",
        body: product,
      }),
    }),

    deleteProduct: builder.mutation<any, string>({
      query: (productId) => ({
        url: `${API_PRODUCT_ENDPOINT}/${productId}`,
        method: "DELETE",
      }),
    }),

    createProductFeature: builder.mutation<any, CreateProductFeatureReq>({
      query: ({ productId, body }) => ({
        url: `${API_PRODUCT_ENDPOINT}/${productId}/features`,
        method: "POST",
        body,
      }),
    }),

    getProductFeatures: builder.query<any, ProductFeature>({
      query: ({ productId, page, pageSize }) =>
        `${API_PRODUCT_ENDPOINT}/${productId}/features?${convertObjectToQueryParams(
          {
            page,
            pageSize,
          }
        )}`,
    }),

    updateProductFeature: builder.mutation<any, UpdateProductFeatureReq>({
      query: ({ productFeatureId, productFeature }) => ({
        url: `${API_PRODUCT_ENDPOINT}/${productFeatureId}`,
        method: "PUT",
        body: productFeature,
      }),
    }),

    deleteProductFeature: builder.mutation<any, DeleteFeatureReq>({
      query: (body) => ({
        url: `${API_PRODUCT_ENDPOINT}/deleteFeatures`,
        method: "DELETE",
        body,
      }),
    }),
  }),
});

export const {
  useCreateProductMutation,
  useGetProductsQuery,
  useUpdateProductMutation,
  useDeleteProductMutation,

  useCreateProductFeatureMutation,
  useGetProductFeaturesQuery,
  useUpdateProductFeatureMutation,
  useDeleteProductFeatureMutation,
} = productApi;
