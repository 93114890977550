import {
  Toolbar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Theme,
  CSSObject,
  styled,
  Box,
} from "@mui/material";
import { useState } from "react";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonIcon from "@mui/icons-material/Person";
import EngineeringIcon from "@mui/icons-material/Engineering";
import InventoryIcon from "@mui/icons-material/Inventory";
import PaidIcon from "@mui/icons-material/Paid";
import MuiDrawer from "@mui/material/Drawer";
import { drawerWidth } from "../../../constants/variables";
import { NavLink as RouterLink } from "react-router-dom";

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(6)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(7)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Sidebar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleDrawerOpen = () => setIsSidebarOpen(true);
  const handleDrawerClose = () => setIsSidebarOpen(false);

  const navConfig = [
    { title: "Dashboard", path: "/dashboard/app", icon: <DashboardIcon /> },
    // { title: "Users", path: "/dashboard/users", icon: <PersonIcon /> },
    // { title: "Roles", path: "/dashboard/roles", icon: <EngineeringIcon /> },
    { title: "Products", path: "/dashboard/products", icon: <InventoryIcon /> },
    // { title: "Sales Tax", path: "/dashboard/sale-tax", icon: <PaidIcon /> },
  ];

  return (
    <Drawer
      variant="permanent"
      open={isSidebarOpen}
      onMouseEnter={handleDrawerOpen}
      onMouseLeave={handleDrawerClose}
    >
      <Toolbar />
      <Box>
        <List disablePadding>
          {navConfig.map((item) => (
            <ListItem key={item.title} disablePadding>
              <ListItemButton
                component={RouterLink}
                to={item.path}
                disableGutters
                sx={{
                  height: 48,
                  fontSize: "14px",
                  position: "relative",
                  textTransform: "capitalize",
                  color: "#545861",
                  '&.active': {
                    color: "#3366ff",
                    bgcolor: "#e4f2fc",
                    fontSize: "14.5px",
                  },
                  "&:hover": {
                    backgroundColor: "#f4f4f4", // Example hover effect
                  },
                }}
              >
                <ListItemIcon sx={{ width: 22, height: 22, color: 'inherit', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.title} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
