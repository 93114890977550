import { Navigate, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard/DashboardLayout";
import SimpleLayout from "./layouts/simple/SimpleLayout";
// pages
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import UserRoles from "./components/UserRoles";
import Products from "./components/Products";
import RoleUsers from "./components/RoleUsers";
import UserProducts from "./components/UserProducts";
import Features from "./components/Features";
import SaleTax from "./pages/SaleTax/SaleTax";
import AddressValidation from "./pages/AddressValidation/AddressValidation";
import ManageProducts from "./components/ManageProducts"; 
import Page404 from "./components/Page404";
// custom auth hook
import { useCheckAuthToken } from "./hooks/useCheckAuthToken";
import { PrivateRoute } from "./util/PrivateRoutes/PrivateRoutes";
import PublicRoute from "./util/PublicRoute"; // Import the PublicRoute component

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    //* Dashboard Layout
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/login" />, index: true },
        {
          path: "app",
          element: (
            <PrivateRoute
              element={<Dashboard />}
              isAuthenticated={useCheckAuthToken}
            />
          ),
          },
          // {
          //   path: "users",
          //   element: <Users />,
          // },
          // {
          //   path: "dealers",
          //   element: <Dealers />,
          // },
        {
          path: "user/:userId/roles",
          element: <UserRoles />,
        },
        {
          path: "user/:userId/products",
          element: <UserProducts />,
        },
        {
          path: "products",
          element: <Products />,
          },

          // {
          //   path: "roles",
          //   element: <Roles />,
          // },

        {
          path: "role/:roleId/users",
          element: <RoleUsers />,
        },
        {
          path: "features",
          element: <Features />,
        },
        {
          path: "manage-products",
          element: <ManageProducts />,
        },
        {
          path: "sale-tax",
          element: <SaleTax />,
        },
      ],
    },

    //* Simple Layout
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/login" />, index: true }, // Default to login
        {
          path: "login",
          element: <PublicRoute element={<Login />} />, // Use PublicRoute for login
        },
        { path: "404", element: <Page404 /> },
      ],
    },

    //* No Layout
    {
      path: "sale-tax",
      element: <SaleTax />,
    },
    {
      path: "address-validation",
      element: <AddressValidation />,
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
