import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { renderCellValue } from "./types/renderCellValue";

export function getFeatureColumns(
  setActions?: (params: GridRowParams<any>) => JSX.Element[],
  renderCellValues?: renderCellValue[]
): GridColDef[] {
  let featureColumns: GridColDef[] = [
    {
      field: "id",
      headerName: "Id",
      filterable: false,
    },
    {
      field: "productFeatureName",
      headerName: "Feature",
      flex: 1,
      filterable: true,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      filterable: false,
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      flex: 1,
      filterable: false,
    },
  ];

  if (setActions)
    featureColumns.push({
      field: "actions",
      headerName: "Actions",
      width: 150,
      type: "actions",

      getActions: (params: GridRowParams<any>) => setActions(params),
    });

  return featureColumns;
}
