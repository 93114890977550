import { everestApi } from "./customAuthApi";
import {
  API_SALES_TAX_ENGINE_BASE_URL,
  API_SALES_TAX_ENGINE_ENDPOINT,
} from "../../constants/endpoints";

const salesTaxApi = everestApi.injectEndpoints({
  endpoints: (builder) => ({
    getSalesTaxEngine: builder.mutation<any, GenericSalesTaxEngineReq>({
      query: (request) => ({
        url: API_SALES_TAX_ENGINE_ENDPOINT,
        method: "POST",
        body: request,
      }),
    }),

    getSalesTaxEngineConfigs: builder.query<any, GenericSalesTaxEngineReq>({
      query: (request) => ({
        url: API_SALES_TAX_ENGINE_ENDPOINT,
        method: "POST",
        body: request,
      }),
      providesTags: ["SalesTax"],
    }),

    updateSalesTaxEngineConfigs: builder.mutation<
      any,
      GenericSalesTaxEngineReq
    >({
      query: (request) => ({
        url: API_SALES_TAX_ENGINE_ENDPOINT,
        method: "POST",
        body: request,
      }),
      invalidatesTags: ["SalesTax"],
    }),

    dealerSalesTaxEngineType: builder.mutation<any, GenericSalesTaxEngineReq>({
      query: (request) => ({
        url: API_SALES_TAX_ENGINE_ENDPOINT,
        method: "POST",
        body: request,
      }),
      invalidatesTags: ["SalesTax"],
    }),

    getCompanyCode: builder.mutation<any, SalesTaxEngineReq>({
      query: ({ DealerId , AccountID, LicenseKey, IsSandboxEnvironment }) => ({
        url: `${API_SALES_TAX_ENGINE_BASE_URL}/GetAvalaraCompanies?DealerId=${DealerId}&AccountID=${AccountID}&LicenseKey=${LicenseKey}&IsSandboxEnvironment=${IsSandboxEnvironment}`,
        method: "GET",
      }),
      invalidatesTags: ["SalesTax"],
    }),

    testAvaleraConnection: builder.mutation<any, SalesTaxEngineReq>({
      query: ({ DealerId, AccountID, LicenseKey, IsSandboxEnvironment }) => ({
        url: `${API_SALES_TAX_ENGINE_BASE_URL}/PingAvalara?DealerId=${DealerId}&AccountID=${AccountID}&LicenseKey=${LicenseKey}&IsSandboxEnvironment=${IsSandboxEnvironment}`,
        method: "POST",
      }),
      invalidatesTags: ["SalesTax"],
    }),

    addressValidation: builder.mutation<any, AddressValidation>({
      query: (request) => ({
        url: `${API_SALES_TAX_ENGINE_BASE_URL}/ValidateAddress`,
        method: "POST",
        body: request,
      }),
      invalidatesTags: ["SalesTax"],
    }),

    getCategories: builder.mutation<any, GenericSalesTaxEngineReq>({
      query: (request) => ({
        url: API_SALES_TAX_ENGINE_ENDPOINT,
        method: "POST",
        body: request,
      }),
      invalidatesTags: ["SalesTax"],
    }),

    getTaxCode: builder.mutation<any, getTaxCodeReq>({
      query: (request) => ({
        url: `${API_SALES_TAX_ENGINE_BASE_URL}/GetAllAvalaraTaxCode?DealerId=${request.dealerId}`,
        method: "GET",
      }),
      invalidatesTags: ["SalesTax"],
    }),

    upsertTaxCodeData: builder.mutation<any, GenericSalesTaxEngineReq>({
      query: (request) => ({
        url: API_SALES_TAX_ENGINE_ENDPOINT,
        method: "POST",
        body: request,
      }),
      invalidatesTags: ["SalesTax"],
    }),

    getSalesTaxTransactionLog: builder.mutation<any, any>({
      query: (request) => ({
        url: API_SALES_TAX_ENGINE_ENDPOINT,
        method: "POST",
        body: request,
      }),
      invalidatesTags: ["SalesTax"],
    }),
  }),
});

export const {
  useGetSalesTaxEngineMutation,
  useGetSalesTaxEngineConfigsQuery,
  useUpdateSalesTaxEngineConfigsMutation,
  useDealerSalesTaxEngineTypeMutation,
  useGetCompanyCodeMutation,
  useTestAvaleraConnectionMutation,
  useAddressValidationMutation,
  useGetCategoriesMutation,
  useGetTaxCodeMutation,
  useUpsertTaxCodeDataMutation,
  useGetSalesTaxTransactionLogMutation,
} = salesTaxApi;
