import { useState, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useAddressValidationMutation } from "../../app/services/salestax";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { UpdateAddressCallback } from "../../util/DmsTrigger";

const AddressValidation = () => {
  const location = useLocation();
  const { pathname } = location;
  const urlParams = new URLSearchParams(window.location.search);

  const DealerId = urlParams.get("dealerId");
  const line1Param = urlParams.get("address");
  const cityParam = urlParams.get("city");
  const regionParam = urlParams.get("region");
  const postalCodeParam = urlParams.get("postalCode");
  const countryParam = urlParams.get("country");

  // Use state to store a timeout ID
  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(
    null
  );
  const [updateIsLoading, setUpdateIsLoading] = useState(false);
  const [addressValidationForm, setAddressValidationForm] =
    useState<AddressValidation>({
      dealerId: null,
      line1: "",
      city: "",
      region: "",
      postalCode: "",
      country: "",
    });

  /*
   * API calls
   * */

  const [
    addressValidation,
    {
      data: addressData,
      error: addressError,
      isError: addressIsError,
      isLoading: addressIsLoading,
      isSuccess: addressIsSuccess,
    },
  ] = useAddressValidationMutation();

  /*
   * Effects
   * */

  useEffect(() => {
    // Check if URL parameters are present
    if (
      DealerId &&
      line1Param &&
      cityParam &&
      regionParam &&
      postalCodeParam &&
      countryParam
    ) {
      // If parameters are present, set them in the state
      setAddressValidationForm((prevSettings) => ({
        ...prevSettings,
        dealerId: Number(DealerId),
        line1: line1Param,
        city: cityParam,
        region: regionParam,
        postalCode: postalCodeParam,
        country: countryParam,
      }));
    }
  }, []);

  useEffect(() => {
    // Check if the form is complete and not in loading state
    if (
      isFormComplete() &&
      !addressIsLoading &&
      !pathname.includes("/dashboard")
    ) {
      // Clear the previous timeout
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }

      const newTimeout = setTimeout(() => {
        // Make the address validation API call
        handleSubmit();
      }, 2000);

      // Set the new timeout in state
      setDebounceTimeout(newTimeout);
    }
  }, [addressValidationForm]);

  /*
   * Handlers
   * */

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setAddressValidationForm((prevSettings) => ({
      ...prevSettings,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    const response = await addressValidation(addressValidationForm);

    //  Empty form
    // setAddressValidationForm((prevState) => ({
    //   ...prevState,
    //   line1: "",
    //   city: "",
    //   region: "",
    //   postalCode: "",
    //   country: "",
    // }));

    // User acknowledgement
    if (response && (response as any)?.data) {
      toast.success("Address validation successfull", { autoClose: 5000 });
    } else {
      toast.error("Address validation failed, Country not supported");
    }
  };

  //* Check if any of the required fields are empty
  const isFormComplete = () => {
    return (
      addressValidationForm.line1 !== "" &&
      addressValidationForm.city !== "" &&
      addressValidationForm.region !== "" &&
      addressValidationForm.postalCode !== "" &&
      addressValidationForm.country !== ""
    );
  };

  const handleUpdateAddress = () => {
    if (addressIsSuccess && addressData) {
      // Start the loading spinner
      setUpdateIsLoading(true);

      setTimeout(() => {
        UpdateAddressCallback(addressData);
        setUpdateIsLoading(false);
      }, 800);
    }
  };

  return (
    <Box
      p={5}
      display="flex"
      flexDirection="column"
      alignItems="left"
      justifyContent="center"
    >
      <Typography mb={3} sx={{ fontWeight: "bold" }}>
        Address Validation
      </Typography>

      <Grid
        container
        spacing={2}
        width="60%"
        // sx={{ border: "1.5px solid gray", borderRadius: "4px" }}
      >
        {/* Left Grid */}
        <Grid item xs={6} p={2}>
          <form>
            <Stack direction="row" alignItems="center" sx={{ marginBottom: 1 }}>
              <Typography variant="body2" sx={{ mr: 1, minWidth: 50 }}>
                Address
              </Typography>

              <TextField
                required
                name="line1"
                value={addressValidationForm.line1}
                onChange={handleChange}
                id="outlined-size-small"
                size="small"
                sx={{ width: "70%" }}
              />
            </Stack>

            <Stack direction="row" alignItems="center" sx={{ marginBottom: 1 }}>
              <Typography variant="body2" sx={{ mr: 1, minWidth: 50 }}>
                City
              </Typography>

              <TextField
                required
                name="city"
                value={addressValidationForm.city}
                onChange={handleChange}
                id="outlined-size-small"
                size="small"
                sx={{ width: "70%" }}
              />
            </Stack>

            <Stack direction="row" alignItems="center" sx={{ marginBottom: 1 }}>
              <Typography variant="body2" sx={{ mr: 1, minWidth: 50 }}>
                State
              </Typography>

              <TextField
                required
                name="region"
                value={addressValidationForm.region}
                onChange={handleChange}
                id="outlined-size-small"
                size="small"
                sx={{ width: "70%" }}
              />
            </Stack>

            <Stack direction="row" alignItems="center" sx={{ marginBottom: 1 }}>
              <Typography variant="body2" sx={{ mr: 1, minWidth: 50 }}>
                Zip
              </Typography>

              <TextField
                required
                name="postalCode"
                value={addressValidationForm.postalCode}
                onChange={handleChange}
                id="outlined-size-small"
                size="small"
                sx={{ width: "70%" }}
              />
            </Stack>

            <Stack direction="row" alignItems="center" sx={{ marginBottom: 1 }}>
              <Typography variant="body2" sx={{ mr: 1, minWidth: 50 }}>
                Country
              </Typography>

              <TextField
                required
                name="country"
                value={addressValidationForm.country}
                onChange={handleChange}
                id="outlined-size-small"
                size="small"
                sx={{ width: "70%" }}
              />
            </Stack>

            <Stack direction="row" mt={3}>
              <Typography variant="body2" sx={{ mr: 1, minWidth: 50 }}>
                {""}
              </Typography>

              <Button
                variant="contained"
                type="submit"
                onClick={handleSubmit}
                disabled={!isFormComplete() || addressIsLoading}
                sx={{
                  textTransform: "capitalize",
                }}
              >
                Validate Address
                {addressIsLoading && (
                  <CircularProgress
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: -12,
                      marginLeft: -12,
                      color: "secondary",
                    }}
                    size={24}
                  />
                )}
              </Button>
            </Stack>
          </form>
        </Grid>

        {/* Right Grid */}
        {addressIsSuccess && addressData && (
          <Grid item xs={6} p={2}>
            <Box
              sx={{
                border: "2px solid black",
                borderRadius: "3px",
                p: 3,
                mb: 3,
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontWeight: "bold",
                  marginBottom: "15px",
                }}
              >
                Validated Address
              </Typography>

              <Typography sx={{ fontSize: "14px" }}>
                {addressData?.line1}
                <br />
                {addressData?.city}
                <br />
                {addressData?.region}
                <br />
                {addressData?.postalCode}
                <br />
                {addressData?.country}
              </Typography>

              <Box
                display="flex"
                alignItems="center"
                justifyContent="end"
                mt={1}
              >
                <Button
                  variant="contained"
                  sx={{ textTransform: "capitalize" }}
                  onClick={handleUpdateAddress}
                  disabled={updateIsLoading}
                >
                  Update
                  {updateIsLoading && (
                    <CircularProgress
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: -12,
                        marginLeft: -12,
                        color: "secondary",
                      }}
                      size={24}
                    />
                  )}
                </Button>
              </Box>
            </Box>

            {/* <Box display="flex" alignItems="center" justifyContent="end">
             <Button
               variant="outlined"
               sx={{ textTransform: "capitalize", width: "40%" }}
             >
               Save
             </Button>
           </Box> */}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default AddressValidation;
