import Header from "../common header/Header";
import { Outlet } from "react-router-dom";
import { Box, Toolbar } from "@mui/material";

// ----------------------------------------------------------------------

const SimpleLayout = () => {
  return (
    <>
      {/*********** Header **********/}
      <Header />

      {/*********** Main ***********/}
      <Box component="main" sx={{ p: 2 }}>
        {/* <Toolbar /> */}
        <Outlet />
      </Box>
    </>
  );
};

export default SimpleLayout;
