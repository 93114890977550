import React, { useEffect, useState } from "react";
import {
  Box,
  Tab,
  Tabs,
  Paper,
  Typography,
  Link,
  Breadcrumbs,
  Stack,
} from "@mui/material";

import ProductForm from "./ProductForm";
import ProductFeature from "./ProductFeature";
import AssignRole from "./ProductAssignRole";
import ManageRole from "./ProductManageRole";
import { useLocation } from "react-router-dom";
import ManageDealer from "./ProductManageDealer";
import { useGetProductsQuery } from "../app/services/product";
import { useProductPaginationModel } from "../app/features/productSlice";
import { setCurrentProductModel, useCurrentProductModel } from "../app/features/manageProductSlice";
import { useAppDispatch } from "../app/hooks";

function ManageProducts() {
  const { state } = useLocation();
  const currentProduct = useCurrentProductModel();
  const dispatch = useAppDispatch();
  const newProductId = localStorage.getItem('newlyCreatedProduct') ?? '';
  let  selectedProductId  = state?.selectedProductId || currentProduct?.id || newProductId || '';
  const [value, setValue] = useState(0);
  const productPaginationModel = useProductPaginationModel();
  
  const handleTabChange = (
    _event: any,
    newValue: React.SetStateAction<number>
  ) => {
    setValue(newValue);
  };

  // Function to switch to ProductFeature tab
const switchToProductFeatureTab = () => {
  setValue(1);
};

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/dashboard/products">
      Products
    </Link>,
    <Typography key="3" color="text.primary">
      {currentProduct.productName}
    </Typography>,
  ];

  const renderTabContent = () => {
    switch (value) {
      case 0:
        return (
          <ProductForm
          selectedProductId={selectedProductId}
          onProductCreate={switchToProductFeatureTab}
        />
        
        );
      case 1:
        return (
          <ProductFeature
            selectedProductId={selectedProductId}
          />
        );
      case 2:
        return (
          <ManageDealer
            selectedProductId={selectedProductId}
          />
        );
      case 3:
        return (
          <ManageRole
            selectedProductId={selectedProductId}
          />
        );
      case 4:
        return (
          <AssignRole
            selectedProductId={selectedProductId}
          />
        );

      default:
        return null;
    }
  };

  /*
   * API calls
   * */

  const {
    data: products,
    isError: productsIsError,
    error: productsError,
    isLoading: productsIsLoading,
    refetch: refetchProducts,
  } = useGetProductsQuery({
    pageSize: productPaginationModel.pageSize,
    page: productPaginationModel.page + 1,
  });
 /*
   * Effects
   * */
 useEffect(() => {
  const newProductId = localStorage.getItem('newlyCreatedProduct');
  const idToSelect = selectedProductId || newProductId;

  if (products) {
    const selectedProduct = products.data.find(
      (product: { id: any }) => product.id === idToSelect
    );
    if (selectedProduct) {
      dispatch(
        setCurrentProductModel({
          id: selectedProduct.id,
          productName: selectedProduct.productName,
          description: selectedProduct.description,
          productType: selectedProduct.productType,
        })
      );
    }
  }
}, [products, selectedProductId]);

  return (
    <Box>
      <Stack spacing={2} sx={{ margin: "5px" }}>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>
      <Paper sx={{ p: 3 }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleTabChange}
            aria-label="product tabs"
            variant="scrollable"  
            scrollButtons="auto"  
            allowScrollButtonsMobile  
          >
            <Tab label="Product" />
            <Tab label="Manage Product Features" />
            <Tab label="Assign Dealers to Product" />
            <Tab label="Manage Roles / Features" />
            <Tab label="Assign Users to Roles" />
          </Tabs>
        </Box>
        {renderTabContent()}
      </Paper>
    </Box>
  );
}

export default ManageProducts;
